.form {
  &--field {
    &.row {
      align-items: center;
      flex-direction: row;
    }
    &.twoRows {
      input {
        margin-left: 0 !important;
      }
    }
    &.textarea {
      width: 100%;
    }
    &.smallTitle {
      .form--field-title {
        font-size: 16px;
      }
    }
    &-title {
      font-size: 20px;
      font-weight: $font-semibold;
      margin-top: 20px;
    }
    &-input {
      input {
        &[type='text'],
        &[type='file'],
        &[type='date'],
        &[type='time'] {
          background: $dark-gray-1;
          border: 0;
          border-radius: 10px;
          color: $white;
          font-size: 16px;
          font-weight: $font-medium;
          height: 50px;
          margin-left: 20px;
          min-width: 250px;
          width: 100%;
          padding: 0 10px;
        }
        &[type='time'] {
          position: relative;
          &::-webkit-calendar-picker-indicator {
            opacity: unset;
            cursor: pointer;
            position: absolute;
            left: unset;
            right: 10px;
            background: url('../../../../assets/images/svg-images/icon-clock-white.svg') !important;
            background-size: contain;
            background-repeat: no-repeat !important;
            background-position: right;
          }
        }
        &[type='date'] {
          position: relative;
          &::-webkit-calendar-picker-indicator {
            opacity: unset;
            cursor: pointer;
            position: absolute;
            left: unset;
            right: 10px;
            background: url('../../images/svg-images/icon-calendar-colored-white.svg') !important;
            background-size: contain;
            background-repeat: no-repeat !important;
            background-position: right;
          }
        }

        &[type='file'] {
          position: relative;
          padding-top: 10px;
          padding-right: 20px;
          padding-left: 55px;
          width: 70%;
          -webkit-appearance: none;
          text-align: left;
          -webkit-rtl-ordering: left;

          &::-webkit-file-upload-button {
            -webkit-appearance: none;
            float: right;
            margin: 0 0 0 10px;
            border: 1px solid #aaaaaa;
            border-radius: 4px;
            background-image: -webkit-gradient(
              linear,
              left bottom,
              left top,
              from(#d2d0d0),
              to(#f0f0f0)
            );
            background-image: -moz-linear-gradient(
              90deg,
              #d2d0d0 0%,
              #f0f0f0 100%
            );
          }

          span {
            display: none;
          }
        }
      }

      textarea {
        background: $dark-gray-1;
        border: 0;
        border-radius: 10px;
        color: $white;
        font-family: $font;
        font-size: 16px;
        font-weight: $font-medium;
        min-height: 200px;
        width: 100%;
        padding: 10px;
        resize: vertical;
      }

      &.addButton {
        position: relative;

        input {
          padding-right: 50px;
        }

        img {
          cursor: pointer;
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      &.uploadIcon {
        position: relative;
        width: 383px;

        input {
          width: 100%;
        }

        img {
          cursor: pointer;
          position: absolute;
          left: 15px;
          top: 50%;
          transform: translateY(-50%);
          z-index: 2;
        }

        .backgroundFill {
          background: $green;
          border-radius: 10px;
          height: 50px;
          left: 0;
          position: absolute;
          opacity: 0.3;
          top: 0;
          z-index: 1;
        }
      }

      &.withDropdown {
        position: relative;
        input {
          padding-right: 150px;
        }

        .Dropdown-root {
          position: absolute !important;
          right: 5px;
          top: 5px;
          width: 140px !important;

          .Dropdown-control {
            background: $black !important;
            border-radius: 10px !important;
            height: 4.8vh !important;
            padding: 5px 10px !important;

            &:before {
              top: 15px !important;
            }
          }

          .Dropdown-menu {
            background: $black !important;
            top: 44px !important;
          }
        }

        .typeLabel {
          position: absolute;
          top: 50%;
          right: 15px;
          transform: translateY(-50%);
          font-weight: bold;
        }
      }
    }
    &__multipleselect {
      width: 80%;

      &-item {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;

        .dropdown-selected {
          align-items: center;
          background: $dark-gray-1;
          padding: 2px 15px;
          border-radius: 15px;
          display: flex;
          justify-content: space-between;
          margin-top: 0;
          margin-right: 10px;

          &:last-of-type {
            margin-right: 0;
          }

          a {
            cursor: pointer;
            font-size: 20px;
            margin-left: 10px;
          }
        }
      }
    }
    &-dateRange {
      background: $dark-gray-1;
      border: 0;
      border-radius: 10px;
      color: $white;
      font-family: $font;
      font-size: 16px;
      font-weight: $font-medium;
      height: 50px;
      margin-left: 20px;
      min-width: 120px;
      width: 100%;
      padding: 0 10px;
    }
    &-marginRight {
      margin-right: 20px;
    }
  }
  &-input {
    &--search {
      background-size: 20px !important;
      &::-moz-placeholder {
        color: $white;
        font-weight: $font-medium;
      }
      &:-ms-input-placeholder {
        color: $white;
        font-weight: $font-medium;
      }
      &:-moz-placeholder {
        color: $white;
        font-weight: $font-medium;
      }
      &::-webkit-input-placeholder {
        color: $white;
        font-weight: $font-medium;
      }

      &.date-range {
        background-image: url('../../images/svg-images/icon-calendar-colored-white.svg');
        opacity: 0.5;
      }
    }
  }
}
.required {
  color: $white !important;

  span {
    color: $orange;
    margin-left: 5px;
  }
}
.checkbox {
  height: 20px;

  input[type='checkbox'].form-input--checkbox:not(:checked) + label:before,
  input[type='checkbox'].form-input--checkbox:not(:checked) + label:after,
  input[type='checkbox'].form-input--checkbox:checked + label:before,
  input[type='checkbox'].form-input--checkbox:checked + label:after {
    border-radius: 5px !important;
    height: 20px;
    width: 20px;
  }

  input[type='checkbox'].form-input--checkbox:checked + label:after {
    background-size: cover;
  }
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: 'Choose Files';
  color: $white;
  cursor: pointer;
  background: $light-gray-2;
  border-radius: 10px;
  padding: 5px 8px;
  position: absolute;
  right: 10px;
  top: 10px;
  height: 32px;
  line-height: 1.4;
  z-index: 2;
}
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}
input[disabled],
select[disabled],
textarea[disabled],
input[readonly],
select[readonly],
textarea[readonly] {
  cursor: not-allowed;
}
