.RMS__page {
  margin-bottom: 50px;
  &--wrapper {
    margin: 0 auto;
    max-width: 1440px;
    width: 100%;
  }
  &--title {
    display: flex;
    justify-content: center;

    h2 {
      font-size: 46px;
      font-weight: $font-bold;
    }
  }
  &--content {
    background-color: $dark-gray-2;
    border-radius: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;

    &.marginBottom {
      margin-bottom: 50px;
    }
    &.disabled-block {
      cursor: not-allowed;
      * {
        cursor: not-allowed;
      }
    }

    &.addRow {
      padding-bottom: 50px;
    }

    .Dropdown-root {
      width: 100% !important;
    }
  }
  &--header {
    padding: 10px 35px;
    &.noMargin {
      margin: 0 !important;
    }
    &-row {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 15px 0;
      &:first-of-type {
        .RMS__page--header {
          &-left {
            p {
              color: $orange;
              font-size: 26px;
            }
            &.filterOn {
              max-width: 60%;
              width: 100%;
            }
          }
          &-right {
            max-width: 20%;
            width: 100%;
            p {
              font-size: 20px;
              font-weight: $font-semibold;
              margin-right: 10px;
            }
            &.filterOn {
              max-width: 30%;
              width: 100%;
              .Dropdown-root {
                max-width: 10vw;
                .Dropdown-control {
                  max-width: 10vw;
                }
                &:last-of-type {
                  .Dropdown-control {
                    margin: 0;
                  }
                }
              }
            }
          }
        }
      }
      &:last-of-type {
        .RMS__page--header {
          &-left {
            align-items: center;
            display: flex;
            max-width: 80%;
            width: 100%;
          }
          &-right {
            max-width: 20%;
            width: 100%;
          }
        }
      }
      &.filterOn {
        max-width: 60%;
        width: 100%;
      }
      &.underline {
        position: relative;

        &::before {
          bottom: 8px;
          background: $light-gray-2;
          content: '';
          position: absolute;
          height: 1px;
          left: 0;
          width: 100%;
        }
      }
    }
    &-right {
      max-width: 20%;
      width: 100%;
      p {
        font-size: 20px;
        font-weight: $font-semibold;
        margin-right: 10px;
      }
      &.filterOn {
        max-width: 30%;
        width: 100%;

        .Dropdown-root {
          max-width: 10vw;
          .Dropdown-control {
            max-width: 10vw;
          }
          &:last-of-type {
            .Dropdown-control {
              margin: 0;
            }
          }
        }
      }
    }
    &:last-of-type {
      .RMS__page--header {
        &-left {
          align-items: center;
          display: flex;
          max-width: 80%;
          width: 100%;
        }
        &-right {
          max-width: 20%;
          width: 100%;
        }
      }
    }

    p {
      margin: 0;
    }

    .Dropdown-root {
      width: 100% !important;
    }

    &-left {
      align-items: center;
      display: flex;
      max-width: 80%;
      width: 100%;
      .form-input--search {
        background-color: unset;
        background-position: right 20px center;
        border-radius: 0;
        height: 100%;
        padding-left: 0;
        width: 100%;
      }
      &.full-width {
        max-width: 100% !important;
      }
    }
    &-right {
      align-items: center;
      display: flex;
      justify-content: space-between;

      max-width: 20%;
      width: 100%;
    }
    &.editRequest,
    &.manageRequests {
      .RMS__page--header {
        &-left {
          max-width: 50%;
        }
        &-right {
          max-width: 50% !important;
          justify-content: flex-end;

          p {
            font-size: 18px;
            margin-right: 20px;

            span {
              color: $orange;
            }
          }
        }
      }
    }
  }
  &--body {
    border-radius: 20px;
    padding: 0 35px;
    position: relative;

    &.black {
      background: $black;
      margin: 0 35px;
      margin-bottom: 40px;
      padding: 20px 35px 70px;
    }

    &-row {
      display: flex;
      justify-content: space-between;
      position: relative;

      .closeButton {
        right: 0 !important;
        top: -10px !important;
      }

      &.twoColumn {
        width: 100%;
        .RMS__page--body-column {
          width: 40%;
        }
      }

      &.threeColumn {
        width: 100%;
        .RMS__page--body-column {
          width: 30%;
        }
      }

      &.fourColumn {
        width: 100%;
        .RMS__page--body-column {
          width: 20%;
        }
      }
    }

    &-column {
      width: 30%;

      &.row {
        align-items: baseline;
        display: flex;

        .Dropdown-root {
          margin-left: 20px;
          max-width: 270px;
        }
      }
    }

    .RMS__page--divider {
      &:before {
        left: 0;
        width: 100%;
      }
    }

    .closeButton {
      color: $white;
      cursor: pointer;
      font-family: $font;
      font-size: 30px;
      font-weight: $font-bold;
      position: absolute;
      right: 20px;
      text-decoration: unset;
      top: 5px;
    }
  }
  &--table {
    overflow: auto;
    &-header,
    &-body {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      padding: 0 35px;
      width: 130%;
    }
    &-header {
      background-color: $black;
    }
    &-body {
      flex-direction: column;
      margin-top: 0;
      max-height: 400px;
      padding-right: 25px;
      overflow-y: scroll;
    }
    &-row {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin: 5px 0;
      width: 100%;

      &.clickable-row {
        cursor: pointer;
      }
    }
    &-column {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      flex: 1;
      min-width: 0;
      padding-right: 10px;
      width: 100%;
      h4 {
        font-size: 16px;
        font-weight: $font-semibold;
        margin: 20px 0;
      }
      p {
        font-size: 14px;
        font-weight: $font-normal;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      img {
        cursor: pointer;
        margin-right: 20px;
      }
      input {
        &[type='text'],
        &[type='date'] {
          background: $dark-gray-1;
          border: 0;
          border-radius: 20px;
          color: $white;
          font-weight: $font-medium;
          width: 120px;
          padding: 0 10px;
        }
      }
    }

    &.user1 {
      .RMS__page--table {
        &-column {
          &:nth-of-type(1) {
            max-width: 2%;

            .checkbox {
              label {
                height: 13px;
              }
            }
          }

          &:nth-of-type(2) {
            max-width: 6%;
          }
          &:nth-of-type(3) {
            max-width: 8%;
          }
          &:nth-of-type(4) {
            max-width: 15%;
          }
          &:nth-of-type(5) {
            max-width: 6%;
          }
          &:nth-of-type(6) {
            max-width: 6%;
          }
          &:nth-of-type(7) {
            max-width: 6%;
          }
          &:nth-of-type(8) {
            max-width: 9%;
          }
          &:nth-of-type(9) {
            max-width: 7%;
          }
          &:nth-of-type(10) {
            max-width: 6%;
          }
          &:nth-of-type(11) {
            max-width: 6%;
          }
          &:nth-of-type(12) {
            max-width: 3%;
          }
        }
      }
    }

    &.userManagementTable {
      .RMS__page--table {
        &-column {
          &:nth-of-type(1) {
            max-width: 2%;

            .checkbox {
              label {
                height: 13px;
              }
            }
          }

          &:nth-of-type(2) {
            max-width: 6%;
          }
          &:nth-of-type(3) {
            max-width: 8%;
          }
          &:nth-of-type(4) {
            max-width: 15%;
          }
          &:nth-of-type(5) {
            max-width: 6%;
          }
          &:nth-of-type(6) {
            max-width: 6%;
          }
          &:nth-of-type(7) {
            max-width: 6%;
          }
          &:nth-of-type(8) {
            max-width: 9%;
          }
          &:nth-of-type(9) {
            max-width: 7%;
          }
          &:nth-of-type(10) {
            max-width: 6%;
          }
          &:nth-of-type(11) {
            max-width: 6%;
          }
          &:nth-of-type(12) {
            max-width: 12%;

            button {
              border-radius: 100px !important;
              font-size: 14px;
              height: 30px !important;
              min-height: unset !important;
              max-width: 160px;
            }
          }
        }
      }
    }

    &.addUsers {
      .RMS__page--table {
        &-body {
          padding: 0;
        }
        &-header,
        &-body {
          width: 100%;

          &.group {
            .RMS__page--table-column {
              &:nth-of-type(3) {
                max-width: 30%;
              }
              &:nth-of-type(4) {
                max-width: 25%;
              }
              &:nth-of-type(6) {
                max-width: 3%;
              }
            }
          }
        }
        &-row {
          padding: 0 35px;
          position: relative;

          &::before {
            bottom: 0px;
            background: $dark-gray-1;
            content: '';
            position: absolute;
            height: 1px;
            left: 0;
            width: 100%;
          }
        }
        &-column {
          &:nth-of-type(1) {
            max-width: 5%;
          }
          &:nth-of-type(2) {
            max-width: 15%;
          }
          &:nth-of-type(3) {
            max-width: 20%;
          }
          &:nth-of-type(4) {
            max-width: 20%;
          }
          &:nth-of-type(5) {
            max-width: 15%;
          }
          &:nth-of-type(6) {
            max-width: 10%;
          }
          &:nth-of-type(7) {
            max-width: 3%;
          }
        }
      }
    }
  }
  &--submit {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 10px 35px;
    padding-top: 30px;
    position: relative;

    &.overall {
      padding-right: 0;
    }
  }
  &--error {
    position: absolute;
    top: 0;
    right: 0;
    &-content {
      color: $red;
      font-size: 16px;
      font-weight: $font-bold;
    }
  }
  &--divider {
    height: 10px;
    margin: 40px 0;
    position: relative;
    width: 100%;

    &:before {
      content: '';
      border-bottom: 2px dashed $light-gray-2;
      height: 2px;
      left: 35px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: calc(100% - 70px);
    }
  }

  &.userManagement {
    .user1 {
      .RMS__page--table {
        &-body {
        }
        &-column {
          &:nth-of-type(1) {
            max-width: 6%;
          }
          &:nth-of-type(2) {
            max-width: 8%;
          }
          &:nth-of-type(3) {
            max-width: 15%;
          }
          &:nth-of-type(4) {
            max-width: 6%;
          }
          &:nth-of-type(5) {
            max-width: 6%;
          }
          &:nth-of-type(6) {
            max-width: 6%;
          }
          &:nth-of-type(7) {
            max-width: 9%;
          }
          &:nth-of-type(8) {
            max-width: 7%;
          }
          &:nth-of-type(9) {
            max-width: 6%;
          }
          &:nth-of-type(10) {
            max-width: 6%;
          }
          &:nth-of-type(11) {
            max-width: 3%;
          }
        }
        &-row {
          &:last-of-type {
            position: relative;

            &::before {
              bottom: 0px;
              background: $light-gray-2;
              content: '';
              position: absolute;
              height: 1px;
              left: 0;
              width: 100%;
            }
          }
        }
      }
      &.archiveUserManagementTable {
        .RMS__page--table {
          &-header,
          &-body {
            width: 200%;
          }
          &-column {
            max-width: 6%;
          }
        }
      }
    }
  }

  &.archivalTrainingRequest {
    .RMS__page {
      &--header {
        &-right {
          justify-content: flex-end;
        }
      }
    }
  }

  &.manageTemplates {
    .RMS__page {
      &--wrapper {
        max-width: 1040px;
      }
      &--header {
        &-right {
          max-width: 25%;
        }
      }
      &--table {
        &-header {
          background-color: $dark-gray-1;
        }
        &-body {
          padding: 0;
        }
        &-header,
        &-body {
          width: 100%;
        }
        &-row {
          padding: 0 35px;
          position: relative;

          &::before {
            bottom: 0px;
            background: $dark-gray-1;
            content: '';
            position: absolute;
            height: 1px;
            left: 0;
            width: 100%;
          }
        }
        &-column {
          &:nth-of-type(1) {
            max-width: 90%;
          }
          &:nth-of-type(2) {
            max-width: 10%;
          }
        }
      }
      &--submit {
        button {
          bottom: -45px;
          position: absolute;
        }
      }
    }
  }

  &.createTemplate {
    .RMS__page {
      &--content {
        position: relative;
        &.marginBottom {
          .RMS__page--body {
            &.black {
              padding-bottom: 20px;

              .firstRow {
                span {
                  font-size: 20px;
                  font-weight: $font-bold;
                  margin: 0 20px;
                }
                .form--field-input {
                  max-width: 100px;
                  width: 100%;
                  input {
                    text-align: center;
                    margin-left: 0;
                    min-width: unset;
                  }
                  &:first-of-type {
                    margin-left: 20px;
                  }
                }
              }
            }
          }
        }

        .btn--add.long {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: -20px;
        }
      }
      &--body {
        .btn--add {
          bottom: -20px;
          position: absolute;
          right: 35px;
        }
      }
      &--submit {
        &.overall {
          padding-right: 0;
          button {
            font-size: 20px;
            min-width: 200px;
          }
        }
      }
    }
  }

  &.createBondTemplate {
    .form--field__multipleselect-item {
      padding-left: 20px;
    }
    .RMS__page {
      &--content {
        &.marginBottom {
          .RMS__page--body {
            &.black {
              padding-bottom: 20px;
            }
            &-column {
              &:first-of-type {
                span {
                  font-size: 20px;
                  font-weight: $font-bold;
                  margin: 0 20px;
                }
                .form--field-input {
                  max-width: 100px;
                  width: 100%;
                  input {
                    text-align: center;
                    margin-left: 0;
                    min-width: unset;
                  }
                  &:first-of-type {
                    margin-left: 20px;
                  }
                }
              }
            }
          }
        }
      }
      &--body {
        .btn--add {
          bottom: -20px;
          position: absolute;
          right: 35px;
        }
      }
      &--submit {
        &.overall {
          padding-right: 0;
          button {
            font-size: 20px;
            min-width: 200px;
          }
        }
      }
    }
  }

  &.trainingForm {
    .RMS__page {
      &--header {
        margin-bottom: 50px;
        &-right {
          max-width: 28%;

          p {
            width: 220px;
          }

          input {
            margin-left: 0;
          }
        }
      }
      &--body {
        .btn--add {
          bottom: -75px;
          position: absolute;
          right: 35px;
        }
        .form--field.row {
          justify-content: space-between;

          input {
            margin-left: 0;
          }
        }
      }
    }
    &.form2 {
      .RMS__page {
        &--header {
          &-right {
            max-width: 30%;

            .Dropdown-root {
              width: 65%;
            }
          }
        }
      }
    }
    &.form1 {
      .RMS__page--body-row {
        &:first-of-type {
          // margin-bottom: 30px;
        }
      }
    }
    &.form3 {
      .form--field.row {
        .Dropdown-root {
          width: 70% !important;
        }
      }
    }
  }

  &.personalDetails {
    .RMS__page {
      &--header {
        &-right {
          max-width: 30%;
        }
        input {
          background-position: right 20px center;
          background-size: 20px !important;
          border-radius: 15px;
          min-height: 50px;
          padding-left: 10px;
          padding-right: 40px;
          width: 100%;
        }
      }
      &--table {
        &-column {
          &:nth-of-type(1) {
            min-width: 11.1%;
          }
          &:nth-of-type(2) {
            min-width: 11.1%;
          }
          &:nth-of-type(3) {
            min-width: 11.1%;
          }
          &:nth-of-type(4) {
            min-width: 11.1%;
          }
          &:nth-of-type(5) {
            min-width: 11.1%;
          }
          &:nth-of-type(6) {
            min-width: 11.1%;
          }
          &:nth-of-type(7) {
            min-width: 11.1%;
          }
          &:nth-of-type(8) {
            min-width: 11.1%;
          }
          &:nth-of-type(9) {
            min-width: 5%;
          }
        }
      }
      &--submit {
        position: relative;

        .btn--add {
          bottom: -45px;
          position: absolute;
        }
      }
    }
  }

  &.accessControl {
    .RMS__page {
      &--wrapper {
        max-width: 1046px;
      }
      &--table {
        &-header,
        &-body {
          width: 100%;
        }
        &-column {
          &:nth-of-type(1) {
            min-width: 3%;
            max-width: 3%;
          }
          &:nth-of-type(2) {
            min-width: 20%;
            max-width: 20%;
          }
          &:nth-of-type(3) {
            min-width: 15%;
            max-width: 15%;
          }
          &:nth-of-type(4) {
            min-width: 20%;
            max-width: 20%;
          }
          &:nth-of-type(5) {
            min-width: 20%;
            max-width: 20%;
          }
        }
      }
    }
  }

  &.manageRequests {
    .RMS__page {
      &--header {
        &-row {
          &:first-of-type {
            .RMS__page--header-right {
              max-width: 480px;
              position: relative;

              .Dropdown-root {
                &:nth-of-type(2) {
                  margin-left: 10px;
                  min-width: 200px;
                }
              }
            }
          }
          &:last-of-type {
            position: relative;

            &::before {
              bottom: 0px;
              background: $light-gray-2;
              content: '';
              position: absolute;
              height: 1px;
              left: 0;
              width: 100%;
            }
          }
        }
        &-right {
          .Dropdown-root {
            &:first-of-type {
            }

            .Dropdown-placeholder {
              width: 100px;
            }
          }
        }
      }
      &--table {
        // &-column {
        //   max-width: 11%;
        //   &:nth-of-type(8) {
        //     &.iconOnly {
        //       max-width: 3%;
        //     }
        //     &.twoButtons {
        //       max-width: 17%;
        //     }
        //     &.iconButton {
        //       max-width: 11%;
        //     }
        //   }
        // }
        &-column {
          &:nth-of-type(1) {
            max-width: 7%;
          }
          &:nth-of-type(2) {
            max-width: 8%;
          }
          &:nth-of-type(3) {
            max-width: 8%;
          }
          &:nth-of-type(4) {
            max-width: 8%;
          }
          &:nth-of-type(5) {
            max-width: 6%;
          }
          &:nth-of-type(6) {
            max-width: 5%;
          }
          &:nth-of-type(7) {
            max-width: 8%;
          }
          &:nth-of-type(8) {
            max-width: 10%;
          }
          &:nth-of-type(9) {
            max-width: 11%;
          }
          &:nth-of-type(10) {
            max-width: 7%;
          }
          &:nth-of-type(11) {
            max-width: 18%;
            &.iconOnly {
              max-width: 3%;
            }
            &.twoButtons {
              max-width: 17%;
            }
            &.iconButton {
              max-width: 11%;
            }
          }
        }
      }
    }

    &.applicant {
      .RMS__page--table {
        &-column {
          &:nth-of-type(8) {
            justify-content: center;
            max-width: 10%;
          }
        }
        &.approvals {
          .RMS__page--table {
            &-body {
            }
            &-column {
              &:nth-of-type(1) {
                max-width: 7%;
              }
              &:nth-of-type(2) {
                max-width: 8%;
              }
              &:nth-of-type(3) {
                max-width: 8%;
              }
              &:nth-of-type(4) {
                max-width: 8%;
              }
              &:nth-of-type(5) {
                max-width: 6%;
              }
              &:nth-of-type(6) {
                max-width: 7%;
              }
              &:nth-of-type(7) {
                max-width: 8%;
              }
              &:nth-of-type(8) {
                max-width: 10%;
              }
              &:nth-of-type(9) {
                max-width: 11%;
              }
              &:nth-of-type(10) {
                max-width: 18%;
              }
            }
          }
        }
        &.pastRequest {
          .RMS__page--table {
            &-body {
              .btn--thinButton {
                color: $orange !important;
                font-size: 14px;
                font-weight: $font-bold;
                min-width: 140px;
              }
            }
          }
        }
      }
    }
  }

  &.manageBonds {
    .RMS__page {
      &--body {
        padding: 0;
      }
      &--table {
        &-header,
        &-body {
          width: 160%;
        }
        &-column {
          max-width: 10%;
          &:nth-of-type(1) {
            max-width: 6%;
          }
          &:nth-of-type(4) {
            max-width: 14%;
          }
          &:nth-of-type(5) {
            max-width: 6%;
          }
          &:nth-of-type(7),
          &:nth-of-type(8),
          &:nth-of-type(9),
          &:nth-of-type(10),
          &:nth-of-type(11) {
            max-width: 10%;
          }
          &:nth-of-type(12) {
            max-width: 5%;

            p {
              font-weight: $font-bold;
            }
          }
          &:nth-of-type(14) {
            max-width: 3.5%;
          }
        }
      }
      &--error {
        margin-right: 20px;
        position: relative;
      }
    }
  }

  &.dashboard {
    .dashboard {
      &__wrapper {
      }
      &--row {
        background-color: $dark-gray-2;
        border-radius: 20px;
        display: flex;
        justify-content: space-between;
        padding: 50px;
        margin-bottom: 20px;
        width: 100%;

        &.dashboard__requests {
          flex-direction: column;
          padding: 30px 0;

          .RMS__page {
            &--header {
              &-right {
                max-width: 25%;

                .btn--loader {
                  height: 30px;
                  width: 33px;
                  min-height: unset !important;
                }
              }
              &-row {
                &.designationFilter {
                  .react-autosuggest__container {
                    width: 100%;
                    input {
                      background: unset;
                      background-image: url('../../../../assets/images/svg-images/icon-search.svg');
                      background-position: right 5px center;
                      background-repeat: no-repeat;
                      background-size: 1.3vw !important;
                      font-size: 2vh;
                      padding-left: 0;
                      &::-moz-placeholder {
                        color: $white;
                        font-weight: $font-medium;
                      }
                      &:-ms-input-placeholder {
                        color: $white;
                        font-weight: $font-medium;
                      }
                      &:-moz-placeholder {
                        color: $white;
                        font-weight: $font-medium;
                      }
                      &::-webkit-input-placeholder {
                        color: $white;
                        font-weight: $font-medium;
                      }
                    }
                    .react-autosuggest__suggestions-container {
                      width: 100%;
                    }
                  }
                }
                &.bondedFilter {
                  justify-content: flex-end;
                  .RMS__page--header-left {
                    margin-right: 20px;
                    max-width: 150px;
                  }
                }
              }
            }
            &--body {
              padding: 0;
            }
            &--table {
              &-header,
              &-body {
                width: 100%;
              }
              &-column {
                &:nth-of-type(1) {
                  max-width: 5%;
                }
              }
            }
          }
        }
        &.userDetails {
          padding: 0;

          .RMS__page {
            &--content {
              width: 100%;
            }
            &--header {
              &-row {
                justify-content: space-between;
              }
            }
            &--body {
              &-row {
                margin-bottom: 20px;
                &:first-of-type {
                  .form--field {
                    justify-content: space-between;
                  }
                  input {
                    margin: 0;
                    min-width: 200px;
                  }
                }
              }
            }
          }
        }
        &.budget {
          flex-direction: column;
          padding: 0;
          padding-bottom: 20px;

          .budget {
            &__row {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              margin-bottom: 20px;
              width: 100%;

              &--title,
              &--calculation {
                width: 100%;
              }

              &--title {
                p {
                  font-size: 18px;
                  font-weight: $font-semibold;
                }
              }

              &--calculation {
                display: flex;
                justify-content: space-between;
              }

              &.total {
                .budget__item p {
                  color: $orange;
                }
              }
            }
            &__item {
              align-items: center;
              background-color: $dark-gray-1;
              border-radius: 30px;
              display: flex;
              justify-content: space-between;
              max-width: 30%;
              padding: 15px 30px;
              width: 100%;

              p {
                font-size: 24px;
                font-weight: $font-medium;
              }
            }
            &__icon {
              max-width: 2.5%;
              position: relative;
              width: 100%;

              &::before {
                content: '';
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                height: 15px;
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 30px;
              }
              &.minus {
                &::before {
                  background-image: url('../../images/svg-images/icon-minus.svg');
                }
              }
              &.equal {
                &:before {
                  background-image: url('../../images/svg-images/icon-equal.svg');
                }
              }
            }
          }
        }
        &.dashboard__request {
          flex-direction: column;
          padding: 0;
          padding-bottom: 40px;

          .RMS__page--header {
            &-right {
              justify-content: flex-end;
            }
          }
        }
      }
      &--column {
        &:nth-of-type(1) {
          max-width: 280px;
          position: relative;
          z-index: 10;
        }
        &:nth-of-type(2) {
          max-width: 375px;
          width: 100%;
        }
        &:nth-of-type(3) {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          max-width: 600px;
        }
        &.normalMode {
          .dashboard--dataWrapper {
            &:first-of-type {
              width: calc(50% - 10px);
            }
          }
        }
        &.groupMode {
          .dashboard--dataWrapper {
            &:last-of-type {
              width: 100%;
            }
          }
        }
      }
      &--dataWrapper {
        align-items: center;
        background-color: $dark-gray-1;
        border-radius: 20px;
        display: flex;
        justify-content: space-between;
        margin: 5px;
        padding: 30px;
        width: calc(50% - 10px);

        &:first-of-type {
          width: 100%;
        }

        p {
          font-size: 24px;
          margin: 0;

          &:nth-of-type(1) {
            max-width: 120px;
          }

          &:nth-of-type(2) {
            font-size: 40px;
          }
        }
      }

      &__graphic {
        .RMS__page {
          &--header {
            padding: 10px 0;
            &-row {
              justify-content: flex-end;
            }
            &-right {
              justify-content: flex-end;
              max-width: 650px;
              width: 100%;

              h4 {
                font-size: 16px;
                font-weight: $font-semibold;
                margin-right: 20px;
              }

              .form--field {
                margin: 0;
                input {
                  margin: 0;
                  &[type='text'] {
                    opacity: unset;
                    cursor: pointer;
                    background-image: url('../../images/svg-images/icon-calendar-orange.svg') !important;
                    background-size: 20px;
                    background-repeat: no-repeat !important;
                    background-position: right 10px center;
                  }
                }

                &:first-of-type {
                  input {
                    min-width: unset;
                    width: 110px;
                  }
                }
              }
            }
          }
        }
        .dashboard--column {
          input[type='text'] {
            margin: 0;
          }
        }
      }
    }
    .RMS__page {
      &--content {
        background: unset;
      }
    }
    .allocations {
      &__wrapper {
      }
      &__data {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 20px;
        &.normal {
          .allocations__data--color {
            background: #f63b00;
          }
          p {
            &:last-of-type {
              color: #f63b00;
            }
          }
        }
        &.group {
          .allocations__data--color {
            background: #bb851b;
          }
          p {
            &:last-of-type {
              color: #bb851b;
            }
          }
        }
        &.balance {
          .allocations__data--color {
            background: #ff9900;
          }
          p {
            &:last-of-type {
              color: #ff9900;
            }
          }
        }
        &--color {
          border-radius: 5px;
          display: inline-block;
          height: 20px;
          margin-right: 15px;
          width: 20px;
        }

        p {
          align-items: center;
          display: flex;
          font-size: 14px;
          font-weight: $font-semibold;

          &:last-of-type {
            font-style: italic;
            margin-left: 35px;
            width: 100%;
          }
        }
      }
    }
    .dataRow {
      h2 {
        font-size: 20px;
        font-weight: $font-semibold;
      }
      &:first-of-type {
        margin-bottom: 30px;
      }
      .react-autosuggest__container {
        margin: 0;
      }
    }
    .piechart-row {
      max-width: 370px;
      width: 100%;
      height: 100%;
      position: relative;
    }
  }

  &.bondsManagement {
    .RMS__page {
      &--header {
        &-left {
          max-width: 70%;
        }
        &-right {
          max-width: 30%;

          .Dropdown-root {
            &:first-of-type {
              margin-right: 10px;
            }
          }
        }
      }
      &--table {
        &-header,
        &-body {
          width: 100%;
        }
      }
    }
  }
  .error-message {
    width: 190px;
    display: flex;
  }
}
