// Neat Bourbon
@import '~bourbon/core/bourbon';
@import '~bourbon-neat/core/neat';

// Settings
// Used with preprocessors and contain font, colors definitions, etc.
// =====================
@import '../../../assets/scss//scss_web/settings/variables';

@import '../../../assets/scss//scss_web/settings/fonts';

// Tools
// Globally used mixins and functions
@import '../../../assets/scss//scss_web/tools/animation';
@import '../../../assets/scss//scss_web/tools/layout';
@import '../../../assets/scss//scss_web/tools/mixins';
@import '../../../assets/scss//scss_web/tools/typography';
@import '../../../assets/scss//scss_web/tools/scrollbar';
@import '../../../assets/scss//scss_web/tools/custom-select';

.RMS_extension {
  // RMS Buttons
  @import 'components/buttons';

  // RMS Overrides
  @import 'overrides/overrides';

  // RMS Tools
  @import 'tools/form';
  @import 'tools/animation';

  @import 'tools/typography';

  @import 'tools/variables';

  // RMS Screens
  @import 'admin/rms-landing';
  @import 'admin/rms-content';
  @import 'admin/rms-popup';
}

.ant-select {
  align-items: center;
  background: $dark-gray-1 !important;
  border: 0 !important;
  border-radius: 10px;
  color: $white !important;
  display: flex !important;
  font-size: 16px !important;
  font-weight: $font-medium;
  height: 50px;
  justify-content: space-between;
  min-width: 200px;
  padding: 0 10px !important;
  // padding-right: 40px !important;
  position: relative;
  width: 100% !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &::before {
    background-image: url('../images/svg-images/orange-dropdown.svg') !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50%;
    content: '';
    color: $white;
    font-size: 16px;
    font-weight: $font-bold;
    height: 30%;
    right: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 28px !important;
  }
  &-clear {
    display: none !important;
  }

  &-selector {
    align-items: center;
    border: 0 !important;
    display: flex;
    justify-content: center;
    height: unset !important;
    padding: unset !important;
    height: 4.5vh !important;
    width: 100%;

    #search-clear {
      display: none;
    }
  }

  &-selection {
    &-search {
      position: relative !important;
      width: 100% !important;
      input {
        background: none !important;
        border: none;
        border-bottom: 1px solid white;
        border-radius: 0 !important;
        color: $white;
        font-size: 1vw;
        padding: 0 !important;
        width: 100%;
      }

      input[type='search']::-webkit-search-decoration,
      input[type='search']::-webkit-search-cancel-button,
      input[type='search']::-webkit-search-results-button,
      input[type='search']::-webkit-search-results-decoration {
        -webkit-appearance: none;
      }
    }
    &-item {
      position: absolute !important;
      left: 8px;
      max-width: 190px;
      overflow: hidden;
      padding: 0 !important;
      top: 50%;
      transform: translateY(-50%);
      width: 100% !important;
    }
    &-placeholder {
      left: 10px;
      position: absolute;
    }
  }

  &-arrow {
    display: none !important;
  }

  &-clear {
    background-color: unset !important;
    right: 40px !important;
    position: relative;
    height: 12px !important;
    width: 12px !important;

    &::before {
      background-color: $red !important;
      border-radius: 100%;
      content: 'X';
      color: $white;
      font-size: 12px;
      font-weight: $font-bold;
      height: 100%;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }

    span {
      display: none;
    }
  }

  &-dropdown {
    background-color: $dark-gray-2 !important;
    border-radius: 10px !important;
    padding: 10px !important;
    pointer-events: auto !important;
    z-index: 100;

    // .rc-virtual-list-holder {
    //   max-height: 200px !important;
    // }

    .ant-empty-description {
      color: $white !important;
      font-weight: $font-bold;
    }
  }

  &-item {
    color: $white !important;
    font-size: 1vw !important;
    padding: 8px 10px !important;
    word-break: break-word !important;

    &:hover,
    &:focus,
    &.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      background-color: $orange !important;
    }

    &.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: $orange !important;
    }
  }
}

.disable-scroll {
  overflow: hidden !important;
}
