.RMS__popUp {
  .reel-view--popup {
    &-container {
      border-radius: 15px;

      input[type='text'] {
        &::-webkit-input-placeholder {
          /* Edge */
          color: $white;
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: $white;
        }

        &::placeholder {
          color: $white;
        }
      }
    }
    &-header {
      h3 {
        color: $orange !important;
        font-size: 28px;
        font-weight: $font-normal;
        margin: 0;
      }

      a {
        font-size: 40px;
        transform: unset;
        top: 30px;
        right: 20px;
      }
    }
    &-body {
      .btn {
        min-height: 10px !important;
      }
    }
    &-row {
      &.submit {
        position: relative;
        .error-message {
          font-size: 14px;
          left: 0;
          margin: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  .form-input--search {
    background-color: unset;
    background-image: url('../../../../assets/images/svg-images/icon-search.svg');
    background-position: right 5px center;
    background-repeat: no-repeat;
    background-size: 1.3vw !important;
    border-radius: 0;
    height: 100%;
    padding-left: 0;
    width: 100%;
  }

  &.addUser {
    .reel-view--popup {
      &-container {
        max-width: 30%;
      }
      &-header {
        padding-bottom: 0;
      }
      &-row {
        align-items: center;
        min-height: 50px;
        &:first-of-type {
          background-color: $dark-gray-2;
          border-radius: 15px;
          display: flex;
          justify-content: space-between;
          padding: 10px;

          input {
            background-color: unset;
            font-weight: $font-medium;
            max-width: 50%;
            padding: 0 !important;
            padding-left: 10px !important;
            width: 100%;
          }

          .Dropdown-root {
            margin-bottom: 0 !important;
            .Dropdown-control {
              border-radius: 15px !important;

              &:before {
                background-image: url('../../images/svg-images/orange-solid-dropdown.svg') !important;
                top: 1.1vw !important;
                right: 1vw !important;
                transform: unset !important;
                width: 1vw !important;
              }

              .Dropdown-placeholder {
                color: $white !important;
                font-weight: $font-bold;
              }
            }
          }
        }
        &:nth-of-type(2) {
          max-height: 30vh;
          height: 100%;
          overflow: auto;
          padding-right: 5px;

          .reel-view--popup-innerRow {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            padding: 5px 10px;
            width: 100%;

            .checkbox {
              height: 40px;
            }

            label {
              font-size: 16px;
              font-weight: $font-medium;
              height: 25px;
              line-height: 1.5;
              margin: 10px;
              margin-left: 15px;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 290px;
              white-space: nowrap;

              &::before,
              &:after {
                top: 0;
              }
            }

            &.selected {
              background-color: $dark-gray-2;
              border-radius: 15px;
            }

            &.noBudget {
              background-color: $rms-darkRed;
              position: relative;

              &::before {
                content: '';
                cursor: not-allowed;
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
                z-index: 10;
              }
            }

            &.disabled {
              cursor: not-allowed;
              opacity: 0.6;

              label {
                cursor: not-allowed;
              }
            }
          }
        }

        &:last-of-type {
          button {
            background-color: $orange;
            border-radius: 15px;
            color: $white;
            font-family: $font;
            font-size: 16px;
            font-weight: $font-medium;
            min-width: 100px;
            padding: 5px 10px;
          }
        }
      }
    }
  }

  .noBudget {
    display: flex;
    justify-content: space-between;

    &--wrapper {
      align-items: center;
      display: flex;
      justify-content: space-between;

      p {
        color: $red;
        font-size: 12px;
      }

      img {
        padding: 0 10px;
      }
    }

    .checkbox
      input[type='checkbox'].form-input--checkbox:not(:checked)
      + label:before {
      background: $red !important;
      background-image: url('../../images/svg-images/notAvailable.svg') !important;
      background-position: center !important;
      background-repeat: no-repeat !important;
      background-size: 75% !important;
      border-color: $red !important;
      border-radius: 100% !important;
    }
  }

  &.wider {
    .reel-view--popup {
      &-container {
        max-width: 70%;
      }
    }
  }

  &.addTempUser {
    .reel-view--popup {
      &-row {
        &:first-of-type {
          display: flex;
          justify-content: space-between;
          .header-column {
            align-items: center;
            display: flex;
            width: 30%;

            h3 {
              font-size: 16px;
              font-weight: $font-semibold;
              margin: 0;
              margin-right: 15px;
            }

            &:first-of-type {
              position: relative;

              &::before {
                bottom: 0px;
                background: $light-gray-2;
                content: '';
                position: absolute;
                height: 1px;
                left: 0;
                width: 100%;
              }
            }
          }
        }
      }
    }
    .RMS__page {
      &--wrapper {
        max-width: 1046px;
      }
      &--table {
        &-header,
        &-body {
          width: 100%;
        }
        &-column {
          &:nth-of-type(1) {
            min-width: 3%;
            max-width: 3%;
          }
          &:nth-of-type(2) {
            min-width: 10%;
            max-width: 10%;
          }
          &:nth-of-type(3) {
            min-width: 25%;
            max-width: 25%;
          }
          &:nth-of-type(4) {
            min-width: 20%;
            max-width: 20%;
          }
          &:nth-of-type(5) {
            min-width: 20%;
            max-width: 20%;
          }
        }
        &.add_admin {
          .RMS__page--table-body {
            .RMS__page--table-column {
              &:nth-of-type(3) {
                padding-left: 3px;
              }
              &:nth-of-type(4) {
                padding-left: 4px;
              }
              &:nth-of-type(5) {
                padding-left: 7px;
              }
            }
          }
        }
      }
    }
  }

  &.editUser {
    .reel-view--popup {
      &-container {
        
      }
      &-body {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        max-height: 60vh;
        overflow-y: auto;
        &__column {
          width: 45%;
        }
        &__row {
          margin-bottom: 20px;
          label {
            display: inline-block;
            font-size: 16px;
            font-weight: $font-semibold;
            margin-bottom: 5px;
          }

          input {
            background-color: $dark-gray-1;
            border-radius: 15px;
            min-height: 40px;
            padding: 5px 10px;
          }
          input[type='date'] {
            border: none;
            color: #fff;
            font-size: 16px;
            padding: 10px;
            width: 100%;
          }
        }
        .error-message {
          font-size: 16px;
        }
        &__column {
          &:first-of-type {
            .reel-view--popup-body__row {
              &:last-of-type {
                .Dropdown-menu {
                  bottom: 55px;
                  top: unset;
                }
              }
            }
          }
        }
      }
      &-row {
        &:last-of-type {
          width: 100%;
        }
      }
    }
    .Dropdown-root {
      width: 100% !important;

      .Dropdown-control {
        background-color: $dark-gray-1 !important;
      }
    }

    input[type='date'] {
      padding: 5px 10px !important;
    }
  }

  &.textMessage {
    .reel-view--popup {
      &-container {
        max-width: 50%;
      }
      &-body {
        padding-top: 0;

        p {
          font-size: 18px;
          font-weight: $font-medium;

          span {
            color: $orange;
          }
        }
        span {
          &.error {
            color: $red;
            font-size: 1vw;
            height: 4vh;
            padding: 1vh 2vh;
            font-weight: $font-medium;
          }
        }
      }
    }
  }

  &.selectTemplate {
    .RMS__page--submit {
      position: relative;

      .btn--add {
        bottom: -45px;
        position: absolute;
      }
    }
    .reel-view--popup {
      &-row {
        &:nth-of-type(1) {
          input {
            background: url('../../../../assets/images/svg-images/icon-search.svg')
              right 0.9vw center no-repeat;
            background-color: $dark-gray-2;
            background-position: right 10px center;
            background-size: 20px !important;
            max-width: unset;
          }
        }
        &:nth-of-type(2) {
          .reel-view--popup-innerRow {
            &.selected {
              background-color: $orange;
            }
          }
        }
      }
    }
  }

  &.FeedBackModal {
    .reel-view--popup {
      &-header {
        justify-content: center;
        h3 {
          color: $white !important;
          font-size: 46px;
          font-weight: $font-bold;
        }
      }
      &-container {
        max-height: 90vh;
        max-width: 80%;
      }
    }
    .RMS__page {
      &--content {
        background: none;
      }
      &--body {
        max-height: 60vh;
        overflow: auto;
        padding: 0;

        &-row {
          &:not(:nth-of-type(2)) {
            padding: 20px 35px;
          }
          &:nth-of-type(2) {
            padding-bottom: 20px;
          }
          .form--field-title {
            margin-bottom: 20px;
            .numbering {
              color: $white !important;
            }
          }
        }
        &-column {
          .form--field-title {
            margin-bottom: 20px;
          }
        }
      }
      &--table {
        width: 100%;

        &-header,
        &-body {
          width: 100%;
        }

        &-header {
          .RMS__page--table-column {
            h4 {
              color: $orange;
              text-align: center;
              width: 100%;
            }
          }
        }

        &-body {
          max-height: unset;
          .RMS__page--table-column {
            span.required {
              color: $red !important;
            }
            &:not(:nth-of-type(1)) {
              justify-content: center;
              label {
                height: 20px;
                width: 100%;
              }

              input[type='radio']:not(:checked) + label:before,
              input[type='radio']:not(:checked) + label:after,
              input[type='radio']:checked + label:before,
              input[type='radio']:checked + label:after {
                left: 50%;
                transform: translateX(-50%);
              }
            }
          }
        }

        &-column {
          &:nth-of-type(1) {
            max-width: 40% !important;
          }
          &:nth-of-type(2) {
            max-width: 10% !important;
          }
          &:nth-of-type(3) {
            max-width: 10% !important;
          }
          &:nth-of-type(4) {
            max-width: 10% !important;
          }
          &:nth-of-type(5) {
            max-width: 10% !important;
          }
          &:nth-of-type(6) {
            max-width: 10% !important;
          }

          p {
            font-size: 16px;
            font-weight: $font-bold;
            margin: 20px 0;
            padding-left: 25px;
            position: relative;

            .numbering {
              left: 0;
              position: absolute;
            }
          }
        }
      }
      &--submit {
        padding: 0 40px 35px !important;
      }
      &--error {
        top: 15px;
        right: 220px;
      }
    }
  }
  &.approvalHistory {
    .reel-view--popup {
      &-container {
        max-width: 30%;
      }
      &-header {
        padding-bottom: 0;
      }
      &-body {
        margin-top: 20px;
        padding: 0;
      }
      &-row {
        align-items: center;
        min-height: 50px;

        &:first-of-type {
          margin-bottom: 0;
          padding: 0 4vh;
          position: relative;

          p {
            color: $light-gray-2;
            right: 9vh;
            position: absolute;
            top: 53px;
          }

          .Dropdown-control {
            background-color: $black !important;

            .Dropdown-placeholder {
              padding-right: 19vh;
              z-index: 10;
            }
          }
        }
        &:last-of-type {
          padding: 4vh;
          padding-top: 0;
        }
      }
    }
    .RMS__page--table {
      &-header,
      &-body {
        width: 100%;
      }
      &-body {
        max-height: 210px;
        padding-top: 10px;
      }
      &-column {
        max-width: 33%;
      }
      &-row {
        min-height: 40px;
      }
    }
  }
  &.dashboard--requestPopup {
    .RMS__page {
      &--header {
        width: 100%;
        &-left {
          max-width: 60%;
        }
        &-right {
          max-width: 40%;
        }
        .Dropdown-root {
          margin-bottom: 0 !important;
          padding-left: 10px;
        }
        &-row {
          &:last-of-type {
            .RMS__page--header {
              &-left {
                max-width: 80%;
              }
              &-right {
                max-width: 20%;
              }
            }
          }
        }
      }
      &--table {
        &-header {
          margin-top: 0;
        }
        .btn {
          min-height: 30px !important;
        }
        &-column {
          &:first-of-type {
            max-width: 3%;
          }
        }
      }
      &--submit {
        padding: 0;
        padding-top: 30px;
      }
    }

    .reel-view--popup {
      &-header {
        .RMS__page--header {
          padding: 10px 35px;
        }
      }
      &-body {
        padding-top: 0;
      }
    }
  }
  &.addApplicant {
    .Dropdown-root {
      width: 210px !important;
    }
    .reel-view--popup {
      &-row {
        &:first-of-type {
          display: flex;
          position: relative;

          &:before {
            background-color: $light-gray-2;
            bottom: 0;
            content: '';
            height: 1px;
            left: 0;
            position: absolute;
            width: calc(100% - 195px);
          }

          input {
            margin-right: 20px;
          }
        }

        &:nth-of-type(2) {
          margin-left: -4vh;
          width: calc(100% + 8vh);

          .RMS__page--table {
            &-header,
            &-body {
              width: 100%;
            }
            &-column {
              max-width: 16%;
            }

            &-row {
              &.clickable-row {
                border: 2px solid $transparent;
                margin-left: -10px;
                width: 100%;
                .RMS__page--table-column {
                  padding-left: 10px;
                }
                &:hover {
                  border: 2px solid $orange;
                  border-radius: 10px;
                }
              }

              &.selected {
                background: $orange;
                border-radius: 10px;
              }
            }
          }
        }
      }
    }
  }
}
