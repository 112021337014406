.RMS__landing {
  &--wrapper {
  }
  &--header {
    display: flex;
    justify-content: flex-end;
  }
  &--body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
    &-title {
      display: flex;
      justify-content: center;
      h1 {
        font-size: 46px;
        font-weight: $font-bold;
      }
    }
    &-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0 auto;
      margin-top: 1vw;
      width: 100%;
      &.part1 {
        max-width: 710px;
      }

      &.part2 {
        max-width: 950px;
        &--v2 {
          max-width: 700px;
        }
        .btn {
          .dropdown-selection {
            display: none;
          }

          &.open {
            height: 315px;

            .dropdown-selection {
              display: block;
            }

            p {
              font-size: 20px;
              font-weight: $font-normal;
              border-radius: 15px;
              padding: 10px;
              margin: 0;
              width: 100%;

              &:hover {
                background-color: $orange;
              }

              &:first-of-type {
                margin-top: 10px;
              }
            }

            img:nth-of-type(2) {
              transform: translateX(-50%) rotate(180deg);
              transform-origin: center;
            }
          }
        }
      }
      .btn {
        align-items: center;
        background: $dark-gray-4;
        border-radius: 20px;
        color: $white;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-family: $font;
        font-size: 1.5vw;
        height: 225px;
        margin-bottom: 0.8vw;
        position: relative;
        text-align: center;
        width: 225px;

        span {
          font-size: 24px;
          line-height: 1.4;
          margin-top: 2vw;
          min-height: 52px;
        }

        img {
          &:nth-of-type(2) {
            cursor: pointer;
            bottom: 0.5vw;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            width: 25px;
          }
        }
      }
    }
  }
}
